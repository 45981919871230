<template>
  <div>
    <h3 class="title white--text">New Default</h3>
    <v-row justify="space-between" class="ma-3">
      <g-button label="Cancel" @onclick="$router.push({ name: 'admin_defaults' })" />
      <div class="d-flex">
        <save-button label="Create" :disabled="!canSave" :onsave="createDefault" />
      </div>
    </v-row>

    <DefaultForm v-model="selected" />
  </div>
</template>

<script>
import { DefaultApi, DefaultForm } from "@/modules/admin.defaults";
export default {
  components: {
    DefaultForm,
  },
  data() {
    return {
      selected: {
        env: null,
        country: null,
        db: null,
        databaseType: null,
        field: null,
        value: null,
      },

      syncing: false,
    };
  },
  computed: {
    canSave() {
      return (
        this.selected.env &&
        this.selected.db &&
        this.selected.databaseType &&
        this.selected.field &&
        this.selected.value
      );
    },
  },
  methods: {
    // NEW DEFAULT
    createDefault() {
      return DefaultApi.addDefault(this.selected)
        .then((v) => {
          this.$root.$success("Default added");
          this.$router.push({ name: "admin_defaults" });
        })
        .catch((err) => this.$root.$error(err));
    },
  },
};
</script>
